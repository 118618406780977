import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'
import localKHR from '@angular/common/locales/km'
import { registerLocaleData } from '@angular/common'

import * as currency from 'currency.js'

registerLocaleData(localKHR, 'KHR');

@Pipe({ name: 'khCurrency' })
export class KhCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }
  transform(value: any): string {
    const riel = value => currency(value, { symbol: "៛", separator: ",", decimal: ".", precision: 0, pattern: '# !' })
    if (value != null) {
      if(value === 0 ) {
        return this.currencyPipe.transform(value, 'KHR' , 'symbol', '0.0', 'KHR')
      }
      return riel(value).format()
      // return this.currencyPipe.transform(value, 'KHR' , 'symbol-narrow', '4.2-2', 'KHR');
    }
       
    return this.currencyPipe.transform(0).split('0.00')[0];
  }
}