import { Injectable } from '@angular/core'

import { PageResultDto, FindOrderDto, OrderItemDto, OrderItemDetailDto, ExportBookingResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class OrderItemService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderItemDto>>('/admin/v1/order-items', findOrderDto)
  }

  async findOne(trackingNumber: string) {
    return await this.httpService.get<OrderItemDetailDto>(`/admin/v1/order-items/${trackingNumber}`)
  }

  async print(trackingNumber: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/order-items/${trackingNumber}/print`)
  }

  async printSmallerSize(trackingNumber: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/order-items/${trackingNumber}/print-smaller-size`)
  }
}
