import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"
import { AssignDeliveryItemDriverDto, CountDeliveryItemDto, PageResultDto, DeliveryItemDto, FindDeliveryItemDto, DeliveryItemDetailDto, CountUnfinishedDeliveryItemDto } from "../dto"

@Injectable()
export class DeliveryItemService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findDeliveryItemDto: FindDeliveryItemDto) {
    return await this.httpService.get<PageResultDto<DeliveryItemDto>>('/admin/v1/delivery-items', findDeliveryItemDto)
  }

  async findOne(trackingNumber: string) {
    return await this.httpService.get<DeliveryItemDetailDto>(`/admin/v1/delivery-items/${trackingNumber}`)
  }

  async assign(assignDeliveryItemDriverDto: AssignDeliveryItemDriverDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/delivery-items/assign-driver`, assignDeliveryItemDriverDto)
  }

  async collect(trackingNumbers: string[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/delivery-items/collect-item-price`, { trackingNumbers })
  }

  async count() {
    return await this.httpService.get<CountDeliveryItemDto>(`/admin/v1/delivery-items/count`)
  }

  async countUnfinished() {
    return await this.httpService.get<CountUnfinishedDeliveryItemDto>(`/admin/v1/delivery-items/count-unfinished`)
  }
}