import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AuthStore } from '@api/store/auth'
import { ProfileDto } from '@api/dto'

import { HttpService } from './http.service'
import { RoleNames } from '../dto/profile.dto'

@Injectable()
export class AuthService {
  constructor(
    private httpService: HttpService,
    private authStore: AuthStore) {
  }

  async current() {
    const user = await this.httpService.get<ProfileDto>('/v1/profile')
    const authUser = _.clone(user)
    const isAdmin = _.intersection(authUser.roleNames, [RoleNames.ADMIN, RoleNames.SUPER_ADMIN])?.length

    this.authStore.update({ user: authUser, roles: authUser.roleNames, isAdmin })

    return user
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    return await this.httpService.patch<Boolean>('/auth/v1/update-password', {
      currentPassword,
      newPassword
    })
  }

  async logIn(email: string, password: string, shouldRemember: boolean) {
    const { sessionToken, user } = await this.httpService.post('/auth/v1/login', {email, password})

    await this.httpService.setSessionToken(sessionToken, shouldRemember)

    return user
  }

  async logOut() {
    await this.httpService.delete('/auth/v1/logout')

    return await this.httpService.removeSessionToken()
  }
}
