import { Injectable } from '@angular/core'

import { PageResultDto, ApprovePaymentDto, FindPaymentRefillDto, PaymentRefillDto, PaymentCountDto, PaymentTotalDto, RejectPaymentDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class PaymentRefillService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPaymentRefillDto: FindPaymentRefillDto) {
    return await this.httpService.get<PageResultDto<PaymentRefillDto>>('/admin/v1/payment-refill', findPaymentRefillDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PaymentRefillDto>(`/admin/v1/payment-refill/${id}`)
  }

  async approve(id: string, approvePaymentDto: ApprovePaymentDto) {
    return await this.httpService.put<boolean>(`/admin/v1/payment-refill/${id}/approve`, approvePaymentDto)
  }

  async reject(id: string, rejectPaymentDto: RejectPaymentDto) {
    return await this.httpService.put<boolean>(`/admin/v1/payment-refill/${id}/reject`, rejectPaymentDto)
  }
  
  async count() {
    return await this.httpService.get<PaymentCountDto>(`/admin/v1/payment-refill/count`)
  }

  async total() {
    return await this.httpService.get<PaymentTotalDto>(`/admin/v1/payment-refill/total`)
  }
}
