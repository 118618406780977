import { Injectable } from '@angular/core'

import { CreateDriverDto, DriverDetailDto, DriverDto, FindDriverDto, PageResultDto, UpdateDriverDto, UserDriverDto, AvailableDriverDto, BalanceDto, ExportBookingResultDto, FindBookingDateFilterDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class DriverService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<DriverDto>>('/admin/v1/drivers', findDriverDto)
  }

  async findBalance(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<BalanceDto>>('/admin/v1/drivers/balance', findDriverDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<DriverDetailDto>(`/admin/v1/drivers/${id}`)
  }

  async update(id: string, updateDriverDto: UpdateDriverDto) {
    return await this.httpService.put<boolean>(`/admin/v1/drivers/${id}`, updateDriverDto)
  }

  async create(createDriverDto: CreateDriverDto) {
    return await this.httpService.post<boolean>(`/admin/v1/drivers`, createDriverDto)
  }

  // async findPending({ searchText, limit, offset }: PagingDto) {
  //   return await this.httpService.get<PageResultDto<DriverSetupDto>>('/admin/v1/drivers/setup', { searchText, limit, offset })
  // }

  // async countPending() {
  //   return await this.httpService.get<CountPendingDriverDto>('/admin/v1/drivers/setup/count')
  // }

  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/drivers/export-payment`, dateFilterDto)
  }

  // async approve(id: string) {
  //   return await this.httpService.post<boolean>(`/admin/v1/drivers/setup`, { driverSetupId: id })
  // }

  async findSimulatedDriver() {
    return await this.httpService.get<UserDriverDto[]>(`/admin/v1/drivers/simulation`)
  }

  // async createSimulatedDriver(numberOfBots: number) {
  //   return await this.httpService.post<boolean>(`/admin/v1/drivers/simulation`, { numberOfBots })
  // }

  async findAvailable(findDriverDto: FindDriverDto) {
    return await this.httpService.get<PageResultDto<AvailableDriverDto>>(`/admin/v1/drivers/available`, findDriverDto)
  }
}
