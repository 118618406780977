import { Injectable } from '@angular/core'

import { DriverDeliveryItemApprovalDto, FindDriverDeliveryItemApprovalDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class DeliveryItemApprovalService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDriverDeliveryItemApprovalDto: FindDriverDeliveryItemApprovalDto) {
    return await this.httpService.get<PageResultDto<DriverDeliveryItemApprovalDto>>('/admin/v1/delivery-items-approval', findDriverDeliveryItemApprovalDto)
  }
}
