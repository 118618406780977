import { FileDto } from './file.dto'

export class ProfileDto {
  fullName: string
  email?: string
  photo: FileDto
  roleNames: RoleNames
  id: string
}

export class UpdateProfileDto {
  public fullName: string
  public email: string

  static create(data: UpdateProfileDto) {
    return Object.assign(new UpdateProfileDto(), data)
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN'
}