import { Injectable } from '@angular/core'

import { FindDriverPaymentDto, DriverPaymentDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class DriverPaymentService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDriverPaymentDto: FindDriverPaymentDto) {
    return await this.httpService.get<DriverPaymentDto[]>('/admin/v1/driver-payment', findDriverPaymentDto)
  }
}
