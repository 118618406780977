import { Injectable } from '@angular/core'

import { PageResultDto, FindPaymentRequestDto, PaidPaymentDto, PaymentRequestDto, PaymentCountDto, PaymentTotalDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class PaymentRequestService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findPaymentRequestDto: FindPaymentRequestDto) {
    return await this.httpService.get<PageResultDto<PaymentRequestDto>>('/admin/v1/payment-request', findPaymentRequestDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PaymentRequestDto>(`/admin/v1/payment-request/${id}`)
  }

  async paid(id: string, paidPaymentDto: PaidPaymentDto) {
    return await this.httpService.put<boolean>(`/admin/v1/payment-request/${id}/paid`, paidPaymentDto)
  }

  async count() {
    return await this.httpService.get<PaymentCountDto>(`/admin/v1/payment-request/count`)
  }

  async total() {
    return await this.httpService.get<PaymentTotalDto>(`/admin/v1/payment-request/total`)
  }
}
