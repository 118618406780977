import { Injectable } from '@angular/core'

import { CityDto, DeliveryZoneDetailDto, UpdateDeliveryRateDto, UpdateDeliveryZoneDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class DeliveryService {
  constructor(
    private httpService: HttpService) {
  }

  async findCity() {
    return await this.httpService.get<CityDto[]>('/v1/cities')
  }

  async findPhnomPenhDistrict() {
    return await this.httpService.get<CityDto[]>('/admin/v1/cities/phnom-penh-districts')
  }

  async find() {
    return await this.httpService.get<CityDto[]>('/admin/v1/cities')
  }

  async findDistricts(cityId: string) {
    return await this.httpService.get<CityDto[]>(`/admin/v1/cities/${cityId}/districts`)
  }

  async findZone(districtId: string) {
    return await this.httpService.get<DeliveryZoneDetailDto>(`/admin/v1/delivery/zones/${districtId}`)
  }

  async updateZone(districtId: string, { enabled, minDays, maxDays }: UpdateDeliveryZoneDto) {
    return await this.httpService.patch<Boolean>(`/admin/v1/delivery/zones/${districtId}`, { enabled, minDays, maxDays })
  }

  async updateRate(rateId: string, { cost }: UpdateDeliveryRateDto) {
    return await this.httpService.patch<Boolean>(`/admin/v1/delivery/rates/${rateId}`, { cost })
  }

  async import(file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.post<Boolean>(`/admin/v1/cities/import`, formData, config)
  }
}
