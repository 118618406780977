import { Injectable } from '@angular/core'

import { BookingItemDto, DriverDeliveryItemApprovalDto, FindBookingItemDto, FindDriverDeliveryItemApprovalDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class DeliveryItemTodayService {
  constructor(
    private httpService: HttpService) {
  }

  async findDriver(findDriverDeliveryItemApprovalDto: FindDriverDeliveryItemApprovalDto) {
    return await this.httpService.get<PageResultDto<DriverDeliveryItemApprovalDto>>('/admin/v1/delivery-items-today/drivers', findDriverDeliveryItemApprovalDto)
  }

  async findItems(findBookingDto: FindBookingItemDto) {
    return await this.httpService.get<PageResultDto<BookingItemDto>>('/admin/v1/delivery-items-today', findBookingDto)
  }
}
