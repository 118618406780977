import { Injectable } from '@angular/core'

import { BalanceDto, ExportBookingResultDto, FindBookingDateFilterDto, FindDriverDto, MerchantDetailDto, MerchantDto, PageResultDto, PaidPaymentDto, TotalBalanceDto, UpdateMerchantDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class MerchantService {
  constructor(
    private httpService: HttpService) {
  }

  async totalBalance() {
    return await this.httpService.get<TotalBalanceDto>(`/admin/v1/merchants/total-balance`)
  }
  
  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/merchants/export-payment`, dateFilterDto)
  }
}
