import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { DashboardDto, DateFilterDto, FindDashboardDto, ResultDto } from "../dto"

@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<DashboardDto>('/admin/v1/dashboard')
  }

  async exportExcel(dateFilterDto?: DateFilterDto) {
    return await this.httpService.get<ResultDto>('/admin/v1/dashboard/export', dateFilterDto)
  }

  async findOneDriver(driverId: string) {
    return await this.httpService.get<DashboardDto>(`/admin/v1/dashboard/${driverId}`)
  }
}