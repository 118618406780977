export class PageInfo {
  public page: number = 1
  public pageSize: number = 10
  public collectionSize: number = 0

  public static create(pageSize: number = 10) {
    const pageInfo = new PageInfo()

    pageInfo.pageSize = pageSize
    pageInfo.page = 1
    pageInfo.collectionSize = 0

    return pageInfo
  }

  public get from() {
    return ((this.page - 1) * this.pageSize) + 1
  }

  public get to() {
    const max = this.page * this.pageSize
    
    return this.collectionSize < max ? this.collectionSize : max
  }

  public get limit() {
    return this.pageSize
  }

  public get offset() {
    return (this.page - 1) * this.pageSize
  }

  public row(row) {
    return (row + 1) + ((this.page - 1) * this.pageSize)
  }
}