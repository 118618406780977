import { Injectable } from '@angular/core'

import { PageResultDto, FindOrderDto, OrderDto, OrderDetailDto, CountBookingDto, UpdateBookingStatusDto, ExportBookingResultDto, FindBookingDateFilterDto, AssignOrderBranchDto, AssignOrderDriverDto, UpdateOrderItemDto, ResultDto, UpdateOrderItemIntoWarehouseDto, UpdateOrderItemIntoWarehouseByTrackingNumberDto, RejectOrderDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/v1/booking', findOrderDto)
  }

  async findOne(bookingNumber: string) {
    return await this.httpService.get<OrderDetailDto>(`/admin/v1/booking/${bookingNumber}`)
  }

  async count() {
    return await this.httpService.get<CountBookingDto>('/admin/v1/orders/count')
  }

  async print(orderNumber: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/orders/${orderNumber}/print`)
  }

  async printSmallerSize(orderNumber: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/orders/${orderNumber}/print-smaller-size`)
  }

  async exportExcel(dateFilterDto: FindBookingDateFilterDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/orders/export`, dateFilterDto)
  }

  async exportOne(orderNumber: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/orders/${orderNumber}/export`)
  }

  async accept(assignOrderBranchDto: AssignOrderBranchDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/accept`, assignOrderBranchDto)
  }

  async reject(rejectBookingDto: RejectOrderDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/reject`, rejectBookingDto)
  }

  async update(orderNumber: string, updateOrderItemDto: UpdateOrderItemDto[]) {
    return await this.httpService.put<Boolean>(`/admin/v1/orders/${orderNumber}`, updateOrderItemDto)
  }

  async delivery(orderNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/${orderNumber}/delivery`, updateStatusDto)
  }

  async complete(orderNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/${orderNumber}/complete`, updateStatusDto)
  }

  async incomplete(orderNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/${orderNumber}/incomplete`, updateStatusDto)
  }

  async arrive(orderNumber: string, updateStatusDto: UpdateBookingStatusDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/${orderNumber}/arrive`, updateStatusDto)
  }

  async assign(assignOrderDriverDto: AssignOrderDriverDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/assign-driver`, assignOrderDriverDto)
  }

  async inwarehouseByTrackingNumber(updateOrderItemIntoWarehouseByTrackingNumberDto: UpdateOrderItemIntoWarehouseByTrackingNumberDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/orders/in-warehouse`, updateOrderItemIntoWarehouseByTrackingNumberDto)
  }

  async importOne(orderNumber: string, file: File) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const formData = new FormData()
    formData.append('file', file)

    return await this.httpService.put<Boolean>(`/admin/v1/orders/${orderNumber}/import`, formData, config)
  }
}
