import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { CountDeliveryItemDto, PageResultDto, CountUnfinishedDeliveryItemDto, FindBookingDto, BookingDto, BookingDetailDto, BookingItemDto, AssignBookingItemDto, CollectBookingItemPaymentDto, UpdateBookingItemDto, CreateBookingDto, ExportBookingItemDto, ExportBookingResultDto, ExportBookingDto, CountBookingItemDto, ResultDto, AssignBookingDto, ExportUserPaymentDto, FindBookingItemDto, RejectBookingItemDto } from "../dto"

@Injectable()
export class BookingService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findBookingDto: FindBookingDto) {
    return await this.httpService.get<PageResultDto<BookingDto>>('/admin/v1/booking', findBookingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BookingDetailDto>(`/admin/v1/booking/${id}`)
  }

  async findItems(findBookingDto: FindBookingItemDto) {
    return await this.httpService.get<PageResultDto<BookingItemDto>>('/admin/v1/booking/items', findBookingDto)
  }

  async findApprovedItems(findBookingDto: FindBookingItemDto) {
    return await this.httpService.get<PageResultDto<BookingItemDto>>('/admin/v1/booking/approved-items', findBookingDto)
  }

  async findCompletedItems(findBookingDto: FindBookingItemDto) {
    return await this.httpService.get<PageResultDto<BookingItemDto>>('/admin/v1/booking/completed-items', findBookingDto)
  }

  async countItems() {
    return await this.httpService.get<CountBookingItemDto>('/admin/v1/booking/count-items')
  }

  async assign(assignItemDto: AssignBookingDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/assign`, assignItemDto)
  }

  async assignItems(assignItemDto: AssignBookingItemDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/assign-items`, assignItemDto)
  }

  async returning(assignItemDto: AssignBookingItemDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/returning`, assignItemDto)
  }

  async reject(rejectDto: RejectBookingItemDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/reject`, rejectDto)
  }

  async complete(trackingNumbers: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/complete`, { trackingNumbers })
  }

  async incomplete(trackingNumbers: string[], reason: string) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/incomplete`, { trackingNumbers, reason })
  }

  async approve(trackingNumbers: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/approve`, { trackingNumbers })
  }

  async bulkDriverApprove(driverIds: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/bulk-approve`, { driverIds })
  }

  async printDriverItems(exportBookingItemDto: ExportBookingItemDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/booking/print-driver-items`, exportBookingItemDto)
  }

  async exportItems(exportBookingDto: ExportBookingDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/booking/export-user-items`, exportBookingDto)
  }

  async exportLeftItems(ownerId: string) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/booking/export-user-left-items`, { ownerId })
  }

  async exportPayment(exportBookingDto: ExportUserPaymentDto) {
    return await this.httpService.get<ExportBookingResultDto>(`/admin/v1/booking/export-user-payment`, exportBookingDto)
  }

  async collectPayment(collectBookingItemPaymentDto: CollectBookingItemPaymentDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/booking/collect-items-payment`, collectBookingItemPaymentDto)
  }

  async findOneItem(trackingNumber: string) {
    return await this.httpService.get<BookingItemDto>(`/admin/v1/booking/items/${trackingNumber}`)
  }

  async updateOneItem(trackingNumber: string, updateItemDto: UpdateBookingItemDto) {
    return await this.httpService.put<Boolean>(`/admin/v1/booking/items/${trackingNumber}`, updateItemDto)
  }

  async create(createBookingDto: CreateBookingDto) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking`, createBookingDto)
  }

  async warehouse(trackingNumbers: string[]) {
    return await this.httpService.post<Boolean>(`/admin/v1/booking/warehouse`, { trackingNumbers })
  }

  async print(trackingNumbers: string[]) {
    return await this.httpService.get<ResultDto>(`/admin/v1/booking/print-items`, { trackingNumbers })
  }

  // async count() {
  //   return await this.httpService.get<CountDeliveryItemDto>(`/admin/v1/delivery-items/count`)
  // }

  // async countUnfinished() {
  //   return await this.httpService.get<CountUnfinishedDeliveryItemDto>(`/admin/v1/delivery-items/count-unfinished`)
  // }
}