import { CommonModule, CurrencyPipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, SettingService, LocationService, PageService, BankService, MerchantService, DriverService, PaymentRequestService, PaymentRefillService, PaymentTransactionService, WindowRef, OrderService, PromotionService, BankInfoService, PushNotificationService, DashboardService, BookingReasonService, DeliveryFeeService, DeliveryItemService, DeliveryService, OrderItemService, DriverPaymentService, BookingService, DeliveryItemApprovalService, DeliveryItemTodayService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { CouponService } from '../services/coupon.service'
import { DragulaService } from 'ng2-dragula'
import { KhCurrencyPipe } from '../utils/kh-currency'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe,
    KhCurrencyPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    CurrencyPipe,
    KhCurrencyPipe,
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    SettingService,
    LocationService,
    CouponService,
    BankService,
    PageService,
    AuthStore,
    AuthQuery,
    MerchantService,
    DriverService,
    PaymentRequestService,
    PaymentRefillService,
    OrderItemService,
    PaymentTransactionService,
    BookingReasonService,
    DeliveryItemApprovalService,
    DeliveryItemTodayService,
    OrderService,
    PromotionService,
    BankInfoService,
    PushNotificationService,
    DeliveryItemService,
    DeliveryService,
    DeliveryFeeService,
    DriverPaymentService,
    DashboardService,
    BookingService,
    WindowRef,
    DragulaService
  ],
  exports: [
    StylesFilterPipe,
    KhCurrencyPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
