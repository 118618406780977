import { Injectable } from '@angular/core'

import { BankDto, CreateBankDto, FindBankDto, PageResultDto, UpdateBankDto } from '../dto'
import { HttpService } from './http.service'
@Injectable()
export class BankService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findBankDto: FindBankDto) {
    return await this.httpService.get<PageResultDto<BankDto>>('/admin/v1/bank', findBankDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<BankDto>(`/admin/v1/bank/${id}`)
  }

  async create({ name, imageId }: CreateBankDto) {
    return this.httpService.post<BankDto>(`/admin/v1/bank`, { name, imageId })
  }

  async update(id: string, { name, imageId, enabled }: UpdateBankDto) {
    return await this.httpService.put<BankDto>(`/admin/v1/bank/${id}`, { name, imageId, enabled })
  }

  async delete(id: string) {
    return await this.httpService.delete(`/admin/v1/bank/${id}`)
  }

  async order(bankIds: string[]) {
    return await this.httpService.put(`/admin/v1/bank/sort-order`, { bankIds })
  }
}
