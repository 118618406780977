import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { PageResultDto } from "../dto"
import { CouponHistoryDto } from "../dto/coupon-history.dto"
import { CouponDetailDto, CouponDto } from "../dto/coupon.dto"
import { FindCouponHistoryDto } from "../dto/find-coupon-history.dto"
import { FindCouponDto } from "../dto/find-coupon.dto"

@Injectable()
export class CouponService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async findHistory(findCouponHistory: FindCouponHistoryDto) {
    return await this.httpService.get<PageResultDto<CouponHistoryDto>>('/admin/v1/coupon/history', findCouponHistory)
  }

  async find(findCouponDto: FindCouponDto) {
    return await this.httpService.get<PageResultDto<CouponDto>>('/admin/v1/coupon', findCouponDto)
  }

  async findOne(couponCode: string) {
    return await this.httpService.get<CouponDetailDto>(`/admin/v1/coupon/${couponCode}`)
  }

  async create({ type, code, amount, startDate, endDate, limitedUse, limitedPerUser, minOrder, maxDiscount, userType, unlimited }: CouponDetailDto)  {
    return this.httpService.post<CouponDto>('/admin/v1/coupon', { type, code, amount, startDate, endDate, limitedUse, userType, unlimited, limitedPerUser, minOrder, maxDiscount })
  }

  async delete(code: string){
    return await this.httpService.delete<CouponDto>(`/admin/v1/coupon/${code}`)
  }
}